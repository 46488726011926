<div
  class="md:h-full mx-auto w-[92%] container items-center flex md:flex-row flex-col justify-between h-[550px] overflow-y-auto">
  <div
    class="rounded-tl-lg md:hidden rounded-br-lg bg-white flex p-8 flex-col justify-between md:min-h-[500px] max-h-[70vh] w-full md:w-[22%] mb-5 md:mb-0">
    <div (click)="openProfile()" class="flex flex-col gap-2 lg:hidden">
      <span class="text-gray-500 flex justify-between">
        <p class="text-[24px] font-black">{{ userName }}</p>
        <img [ngClass]="{ '-rotate-90': !profileDisplay }" class="" src="../../assets/icons/chevron-down-stepper.svg"
          alt="chevron down" />
      </span>
      <!-- <p class="text-[14px] font-medium">
        Bevorstehende Reinigung am
        {{ this.filteredTimeSlots[0] | date : "dd MMMM yyyy" }}
      </p> -->
    </div>
    @if(profileDisplay) {
    <div>
      <ul class="flex flex-col gap-4 text-[20px] py-5 font-bold text-[#B4B4B4]">
        <li (click)="handleTabs('Reservations')" [ngClass]="{ 'text-[#346973]': getActiveTab('Reservations') }">
          <img [src]="
                getActiveTab('Reservations')
                  ? '../../assets/icons/profile/list-active.svg'
                  : '../../assets/icons/profile/list.svg'
              " alt="icon" />
          {{ 'PROFILE.reservations' | translate }}
        </li>
        <li [ngClass]="{ 'text-[#346973]': getActiveTab('Orders') }" (click)="handleTabs('Orders')">
          <img [src]="
                getActiveTab('Orders')
                  ? '../../assets/icons/profile/bag-active.svg'
                  : '../../assets/icons/profile/bag.svg'
              " alt="icon" />
          {{ 'PROFILE.paymenthistory' | translate }}
        </li>
        <li [ngClass]="{ 'text-[#346973]': getActiveTab('Profile') }" (click)="handleTabs('Profile')">
          <img [src]="
                getActiveTab('Profile')
                  ? '../../assets/icons/profile/person-active.svg'
                  : '../../assets/icons/profile/person.svg'
              " alt="icon" />
          {{ 'PROFILE.profile' | translate }}
        </li>
        <li [ngClass]="{ 'text-[#346973]': getActiveTab('Settings') }" (click)="handleTabs('Settings')">
          <img [src]="
                getActiveTab('Settings')
                  ? '../../assets/icons/profile/settings-active.svg'
                  : '../../assets/icons/profile/settings.svg'
              " alt="icon" />
          {{ 'PROFILE.settings' | translate }}
        </li>
      </ul>
    </div>
    <div class="flex flex-col gap-2">
      <!-- <span class="text-gray-500">
          <p class="text-[24px] font-black">{{ userName }}</p>
        </span> -->
      <p class="text-[14px] font-medium">
        {{ 'PROFILE.upcomingcleaning' | translate }}
        {{this.filteredTimeSlots[0] | date : "dd"}} {{  'DATE.' + (this.filteredTimeSlots[0] | date : "MMMM") | translate }} {{this.filteredTimeSlots[0] | date : "YYYY"}}
      </p>
      <!-- Promo button will be available at v2 -->
      <!-- <button class="border-[1px] border-[#34c759] justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#34c7593a] text-[#34c759] hover:duration-100 flex items-center gap-1">Gutschein & Promo <img src="../../assets/icons/profile/percent.svg" alt=""></button> -->
      <!-- @if(paymentType === "CARD") { -->
      <button (click)="handleTabs('CCard')"
        class="border-[1px] justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#ffcc303a] text-[#ffcc30] border-[#ffcc30] hover:duration-100 flex items-center gap-1">
        {{ 'PROFILE.payment' | translate }} <img src="../../assets/icons/profile/card.svg" alt="" />
      </button>
      <!-- } -->
      <button
        class="border-[1px] border-[#ff3b30] p-2 justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#ff3b303a] text-[#ff3b30] hover:duration-100 flex items-center gap-1"
        (click)="logout()">
        {{ 'PROFILE.logout' | translate }} <img src="../../assets/icons/profile/logout.svg" alt="" />
      </button>
    </div>
    }
  </div>
  <div
    class="rounded-tl-lg hidden rounded-br-lg bg-white md:flex p-8 flex-col justify-between md:min-h-[450px] h-[70vh] w-full md:w-[22%] mb-5 md:mb-0">
    <div>
      <ul class="flex flex-col gap-4 text-[20px] font-bold text-[#B4B4B4]">
        <li (click)="handleTabs('Reservations')" [ngClass]="{ 'text-[#346973]': getActiveTab('Reservations') }">
          <img [src]="
              getActiveTab('Reservations')
                ? '../../assets/icons/profile/list-active.svg'
                : '../../assets/icons/profile/list.svg'
            " alt="icon" />
          {{ 'PROFILE.reservations' | translate }}
        </li>
        <li [ngClass]="{ 'text-[#346973]': getActiveTab('Orders') }" (click)="handleTabs('Orders')">
          <img [src]="
              getActiveTab('Orders')
                ? '../../assets/icons/profile/bag-active.svg'
                : '../../assets/icons/profile/bag.svg'
            " alt="icon" />
          {{ 'PROFILE.paymenthistory' | translate }}
        </li>
        <li [ngClass]="{ 'text-[#346973]': getActiveTab('Profile') }" (click)="handleTabs('Profile')">
          <img [src]="
              getActiveTab('Profile')
                ? '../../assets/icons/profile/person-active.svg'
                : '../../assets/icons/profile/person.svg'
            " alt="icon" />
          {{ 'PROFILE.profile' | translate }}
        </li>
        <li [ngClass]="{ 'text-[#346973]': getActiveTab('Settings') }" (click)="handleTabs('Settings')">
          <img [src]="
              getActiveTab('Settings')
                ? '../../assets/icons/profile/settings-active.svg'
                : '../../assets/icons/profile/settings.svg'
            " alt="icon" />
          {{ 'PROFILE.settings' | translate }}
        </li>
      </ul>
    </div>
    <div class="flex flex-col gap-2">
      <span class="text-gray-500">
        <p class="text-[24px] font-black">{{ userName }}</p>
        <!-- <p>Guthaben: CHF 126.70</p> -->
      </span>
      <p class="text-[14px] font-medium">
        {{ 'PROFILE.upcomingcleaning' | translate }}
        {{this.filteredTimeSlots[0] | date : "dd"}} {{  'DATE.' + (this.filteredTimeSlots[0] | date : "MMMM") | translate }} {{this.filteredTimeSlots[0] | date : "YYYY"}}
      </p>
      <!-- Promo button will be available at v2 -->
      <!-- <button class="border-[1px] border-[#34c759] justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#34c7593a] text-[#34c759] hover:duration-100 flex items-center gap-1">Gutschein & Promo <img src="../../assets/icons/profile/percent.svg" alt=""></button> -->
      <!-- @if(paymentType === "CARD") { -->
      <button (click)="handleTabs('CCard')"
        class="border-[1px] justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#ffcc303a] text-[#ffcc30] border-[#ffcc30] hover:duration-100 flex items-center gap-1">
        {{ 'PROFILE.payment' | translate }} <img src="../../assets/icons/profile/card.svg" alt="" />
      </button>
      <!-- } -->
      <button
        class="border-[1px] border-[#ff3b30] p-2 justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#ff3b303a] text-[#ff3b30] hover:duration-100 flex items-center gap-1"
        (click)="logout()">
        {{ 'PROFILE.logout' | translate }} <img src="../../assets/icons/profile/logout.svg" alt="" />
      </button>
    </div>


  </div>

  <app-modal 
  [selectedDetails]="this.selectedDetails" [detailsOpen]="this.detailsOpen"
    [closeDetails]="closeDetails.bind(this)" [cancelCleaningModal]="this.cancelCleaningModal"
    [cancelCleaning]="this.cancelCleaning" [displayCancellationMessage]="this.displayCancellationMessage"
    [submitCancellation]="submitCancellation.bind(this)" [additionalServicesMoadl]="this.additionalServicesMoadl"
    [additionalServicesForm]="this.additionalServicesForm" [price]="this.price" [requiredRemark]="this.requiredRemark"
    [handleIroningAndCabinets]="handleIroningAndCabinets.bind(this)"
    [handleFridgeAndOven]="handleFridgeAndOven.bind(this)"
    [cancelAdditionalServices]="cancelAdditionalServices.bind(this)"
    [postAdditionalServices]="postAdditionalServices.bind(this)" [reactivationModal]="this.reactivationModal"
    [submitReactivation]="submitReactivation.bind(this)" [formatCleaningDays]="formatCleaningDays.bind(this)"
    [handleCleaningStatus]="handleCleaningStatus.bind(this)" [handleRecurrence]="handleRecurrence.bind(this)"
    [handleType]="handleType.bind(this)" [cancellationLeft]="this.cancellationLeft" [hasUnlimitedCancellations]="this.hasUnlimitedCancellations"
    [transformDataAndCalculateDuration]="transformDataAndCalculateDuration.bind(this)" [cleaningPrice]="this.cleaningPrice">
  </app-modal>

  <div [ngClass]="{
    'relative' : vacationDisplay,}" class="rounded-tl-lg rounded-br-lg bg-white w-full md:w-[76%] p-8 md:h-[70vh] md:overflow-y-auto">
    
    @switch (selectedTab) { @case('Reservations') {
    <ng-template [ngTemplateOutlet]="Reservations"></ng-template>
    } @case('Profile'){
    <ng-template [ngTemplateOutlet]="Profile"></ng-template>
    } @case('Settings'){
    <ng-template [ngTemplateOutlet]="Settings"></ng-template>
    } @case('Orders'){
    <ng-template [ngTemplateOutlet]="Orders"></ng-template>
    } @case('CCard'){
    <ng-template [ngTemplateOutlet]="CCard"></ng-template>
    } }
  </div>
</div>

<ng-template #Reservations>
    <div class="flex flex-col gap-6">
    <div *ngIf=vacationDisplay class="absolute text-white w-full overflow-hidden left-0 top-0 bg-[#FA8D8D]">
      <p class="pl-8 my-2">{{'PROFILE.vacationmsg' | translate}}</p>
    </div>
    <div [ngClass]="{'mt-20 md:mt-5' : vacationDisplay}"ass="flex items-center">
      <span class="bg-violet-100 text-violet-400 rounded-md text-[20px] font-bold uppercase px-5 py-2">{{ 'PROFILE.reservations' | translate }}</span>
    </div>
    <div class="flex flex-col md:flex-row items-center gap-4">
      <button class="w-full md:w-fit" (click)="handleReservationTab('All')" [ngClass]="{
          'bg-[#61BFD1] text-white hover:bg-[#61bfd185]':
            reserVationTab === 'All',
          'border-[#00829a] hover:opacity-55 text-[#00829a]':
            reserVationTab != 'All',
          'px-8 py-2 box-border font-bold rounded-md border-[1px]': true
        }">
        {{ 'PROFILE.all' | translate }}
      </button>
      <button class="w-full md:w-fit" (click)="handleReservationTab('Upcoming')" [ngClass]="{
          'bg-[#61BFD1] text-white hover:bg-[#61bfd185]':
            reserVationTab === 'Upcoming',
          'border-[#00829a] hover:opacity-55 text-[#00829a]':
            reserVationTab != 'Upcoming',
          'px-8 py-2 box-border font-bold rounded-md border-[1px]': true
        }">
        {{ 'PROFILE.upcoming' | translate }}
      </button>
      <button class="w-full md:w-fit" (click)="handleReservationTab('Past')" [ngClass]="{
          'bg-[#61BFD1] text-white hover:bg-[#61bfd185]':
            reserVationTab === 'Past',
          'border-[#00829a] hover:opacity-55 text-[#00829a]':
            reserVationTab != 'Past',
          'px-8 py-2 box-border font-bold rounded-md border-[1px]': true
        }">
        {{ 'PROFILE.past' | translate }}
      </button>
    </div>
    <div class="flex flex-col md:flex-row w-full justify-start font-bold px-4 text-[18px] text-[#00829a] pb-2">
      <p class="md:w-[20%] w-full">{{ 'PROFILE.dateandtime' | translate }}</p>
      <p class="md:w-[20%] w-full">{{ 'AUTH.address' | translate }}</p>
      <p class="md:w-[20%] w-full">{{ 'PROFILE.cleaning' | translate }}</p>
      <p class="md:w-[20%] w-full">{{ 'PROFILE.duration' | translate }}</p>
      <p class="md:w-[20%] w-full">Status</p>

    </div>
    @switch (reserVationTab) { @case('All'){ @if(bookings?.length > 0){ @for(item of
    bookings; track $index; let i = $index){
    <div class="flex justify-between flex-row flex-wrap rounded-lg px-4 py-6 shadow text-[#a3a3a3] relative">
      @if(item.appointmentDate === null || undefined) {
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
        {{ 'PROFILE.date' | translate }}
        <p>{{ 'PROFILE.requested' | translate }}</p>
      </span>
      } @else {
      @if(item.cleaningDays) {
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
        {{ 'DATE.' + (item.startDate | date : "EEEE") | translate }}
        <p>{{item.startDate | date : "dd."}} {{  'DATE.' + (item.startDate | date : "MMMM") | translate }} {{item.startDate | date : "YYYY"}}</p>
      </span>
      } @else {
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
        {{ item.appointmentDate | date:'EEEE'}}
        <p>{{item.appointmentDate | date : "dd."}} {{  'DATE.' + (item.appointmentDate | date : "MMMM") | translate }} {{item.appointmentDate | date : "YYYY"}}</p>
      </span>
      }
      }
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
        {{ item.address.street }} {{ item.address.streetNr }}
        <p>{{ item.address.zipCode }} {{ item.address.city }}</p>
      </span>
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
        <span class="flex items-center gap-1">
          <img src="../../assets/icons/profile/home.svg" alt="home" />
          {{ 'SERVICE_TYPE.' + handleType(item.type) | translate}}
        </span>
        <span class="flex items-center gap-1"><img src="../../assets/icons/profile/recurrence.svg" alt="recurrence" />
          {{'RECURRENCE_TYPE.' + handleRecurrence(item.recurrence) | translate}}
        </span>
      </span>
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
        @if(item.cleaningDays) {
          @if(item.projektnummer) {
            <span class="flex items-center gap-1">
              {{ 'PROFILE.duration' | translate }}
            </span>
            <span class="flex items-center gap-1">
              {{ transformDataAndCalculateDuration(item.additionalServices, item.startDate, item.endDate) + " " + ('PROFILE.hours' | translate)}}
            </span>
          } @else {
            <span class="flex items-center gap-1">
              {{ 'PROFILE.duration' | translate }}
            </span>
            <span class="flex items-center gap-1">
              {{ item.cleaningDays.length > 0 ? item.cleaningDays[0].duration + " " + ('PROFILE.hours' | translate) :
              formatCleaningDays(item.startDate, item.endDate) + " " + ('PROFILE.hours' | translate)}}
            </span>
          }
      }
      </span>
      <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
        @if(item.cleaningDays) {
        <span class="my-auto">
          <span [ngClass]="{
                  'text-[#ffcc30] border-[#ffcc30]' : item.status === 'PENDING' || item.status === 'pending',
                  'border-[#ff3b30] text-[#ff3b30]' : item.status === 'CANCELLED' || item.status === 'inaktiv',
                  'border-[#34C759] text-[#34C759]' : item.status === 'ACTIVE' || item.status === 'aktiv',
                }" class="border-[1px] border-[#61BFD1] p-1 rounded-lg">{{ 'STATUS_TYPE.' + handleCleaningStatus(item.status) | translate }}</span>
        </span>
        } @else {
        <span class="my-auto">
          @if(item.isSynchronizedCreate) {
          <span class="border-[1px] border-[#34C759] text-[#34C759] p-1 rounded-lg">{{'STATUS_TYPE.' + 'Aktiv' | translate}}</span>
          } @else {
          <span class="text-[#ffcc30] border-[#ffcc30] border-[1px] p-1 rounded-lg">{{'STATUS_TYPE.' + 'Ausstehend' | translate}}</span>
          }
        </span>
        }
      </span>
      @if(item.status === 'inaktiv' || item.status === "CANCELLED" || checkForPastDate(item.startDate) ||
      item.cleaningType === "Express" || item.cleaningType === "EXPRESS" || item.recurrence === "ONCE") {
      <div class="relative flex justify-center items-center ">
        <div (click)="handleCleaningEdit(item.id, item.projektnummer)"
          class="border-[1px] rounded-lg border-[#61BFD1] absolute flex justify-center items-center right-12 md:right-4 w-[33px] h-[30px] md:top-4 cursor-pointer">
          <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
        </div>
        @if(isCleaningOpen(item.id, item.projektnummer)){
        <div class="text-white absolute top-10 -left-24 z-10 flex flex-col rounded-lg bg-[#61BFD1]">
          <button (click)="handleSettings(item , i)"
            [ngClass]="{'rounded-t-lg rounded-b-none' : (item.status === 'CANCELLED' || 'inaktiv')}"
            class="hover:bg-[#63939c] rounded-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
            {{ 'PROFILE.details' | translate }}
          </button>
          <button
            *ngIf="checkCleaningStatus(item.status) && !checkForPastDate(item.startDate) && item.cleaningType === checkCleaningType(item.cleaningType)"
            (click)="handleReactivationCleaning(item, i)"
            class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
            {{ 'PROFILE.reactivation' | translate }}
          </button>
        </div>
        }
      </div>
      } @else {
      <div class="relative flex justify-center items-center ">
        <div (click)="handleCleaningEdit(item.id, item.projektnummer)"
          class="border-[1px] rounded-lg border-[#61BFD1] absolute flex justify-center items-center right-12 md:right-4 w-[33px] h-[30px] md:top-4 cursor-pointer">
          <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
        </div>
        @if(isCleaningOpen(item.id, item.projektnummer)){
        <div class="text-white absolute top-10 -left-24 z-10 flex flex-col rounded-lg bg-[#61BFD1]">
          <button (click)="handleSettings(item , i)"
            class="hover:bg-[#63939c] rounded-t-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
            {{ 'PROFILE.details' | translate }}
          </button>

          <button [ngClass]="{'rounded-b-none' : item.cleaningType === checkCleaningType(item.cleaningType)}"
            (click)="handleCancelCleanings(item, i)"
            class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
            {{ 'PROFILE.cancel' | translate }}
          </button>
          <button *ngIf="item.cleaningType === checkCleaningType(item.cleaningType)"
            (click)="handleAdditionalServices(item, i)"
            class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
            {{ 'PROFILE.additional' | translate }}
          </button>
        </div>
        }
      </div>
      }
    </div>
    } } 
    @else {
    @if(loadingBookings){
    <div class="flex w-full h-[240px] bg-slate-300 animate-pulse rounded-lg"></div>
    } @else {
    <div class="flex justify-center items-center h-[240px] w-full">
      <div class="flex items-center flex-col gap-4">
        <img class="w-36" src="../../assets/icons/profile/cleaninglady.svg" alt="cleaninglady">
        <p class="font-bold text-[18px]">{{ 'PROFILE.Keine Reinigungen? Jetzt' | translate }} <a class="underline" href="/">{{ 'PROFILE.reservieren' | translate }}</a></p>
      </div>
    </div>
    }
    }

    } @case('Upcoming'){ @if(getBookings('upcoming').length > 0 ){ @for(item
    of getBookings('upcoming'); track $index; let i = $index;){
      <div class="flex justify-between flex-row flex-wrap rounded-lg px-4 py-6 shadow text-[#a3a3a3] relative">
        @if(item.appointmentDate === null || undefined) {
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
          {{ 'PROFILE.date' | translate }}
          <p>{{ 'PROFILE.requested' | translate }}</p>
        </span>
        } @else {
        @if(item.cleaningDays) {
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
          {{ 'DATE.' + (item.startDate | date : "EEEE") | translate }}
          <p>{{item.startDate | date : "dd."}} {{  'DATE.' + (item.startDate | date : "MMMM") | translate }} {{item.startDate | date : "YYYY"}}</p>
        </span>
        } @else {
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
          {{ item.appointmentDate | date:'EEEE'}}
          <p>{{item.appointmentDate | date : "dd."}} {{  'DATE.' + (item.appointmentDate | date : "MMMM") | translate }} {{item.appointmentDate | date : "YYYY"}}</p>
        </span>
        }
        }
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          {{ item.address.street }} {{ item.address.streetNr }}
          <p>{{ item.address.zipCode }} {{ item.address.city }}</p>
        </span>
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          <span class="flex items-center gap-1">
            <img src="../../assets/icons/profile/home.svg" alt="home" />
            {{ 'SERVICE_TYPE.' + handleType(item.type) | translate}}
          </span>
          <span class="flex items-center gap-1"><img src="../../assets/icons/profile/recurrence.svg" alt="recurrence" />
            {{'RECURRENCE_TYPE.' + handleRecurrence(item.recurrence) | translate}}
          </span>
        </span>
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          @if(item.cleaningDays) {
            @if(item.projektnummer) {
              <span class="flex items-center gap-1">
                {{ 'PROFILE.duration' | translate }}
              </span>
              <span class="flex items-center gap-1">
                {{ transformDataAndCalculateDuration(item.additionalServices, item.startDate, item.endDate) + " " + ('PROFILE.hours' | translate)}}
              </span>
            } @else {
              <span class="flex items-center gap-1">
                {{ 'PROFILE.duration' | translate }}
              </span>
              <span class="flex items-center gap-1">
                {{ item.cleaningDays.length > 0 ? item.cleaningDays[0].duration + " " + ('PROFILE.hours' | translate) :
                formatCleaningDays(item.startDate, item.endDate) + " " + ('PROFILE.hours' | translate)}}
              </span>
            }
        }
        </span>
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          @if(item.cleaningDays) {
          <span class="my-auto">
            <span [ngClass]="{
                    'text-[#ffcc30] border-[#ffcc30]' : item.status === 'PENDING' || item.status === 'pending',
                    'border-[#ff3b30] text-[#ff3b30]' : item.status === 'CANCELLED' || item.status === 'inaktiv',
                    'border-[#34C759] text-[#34C759]' : item.status === 'ACTIVE' || item.status === 'aktiv',
                  }" class="border-[1px] border-[#61BFD1] p-1 rounded-lg">{{ 'STATUS_TYPE.' + handleCleaningStatus(item.status) | translate }}</span>
          </span>
          } @else {
          <span class="my-auto">
            @if(item.isSynchronizedCreate) {
            <span class="border-[1px] border-[#34C759] text-[#34C759] p-1 rounded-lg">{{'STATUS_TYPE.' + 'Aktiv' | translate}}</span>
            } @else {
            <span class="text-[#ffcc30] border-[#ffcc30] border-[1px] p-1 rounded-lg">{{'STATUS_TYPE.' + 'Ausstehend' | translate}}</span>
            }
          </span>
          }
        </span>
        @if(item.status === 'inaktiv' || item.status === "CANCELLED" || checkForPastDate(item.startDate) ||
        item.cleaningType === "Express" || item.cleaningType === "EXPRESS" || item.recurrence === "ONCE") {
        <div class="relative flex justify-center items-center ">
          <div (click)="handleCleaningEdit(item.id, item.projektnummer)"
            class="border-[1px] rounded-lg border-[#61BFD1] absolute flex justify-center items-center right-12 md:right-4 w-[33px] h-[30px] md:top-4 cursor-pointer">
            <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
          </div>
          @if(isCleaningOpen(item.id, item.projektnummer)){
          <div class="text-white absolute top-10 -left-24 z-10 flex flex-col rounded-lg bg-[#61BFD1]">
            <button (click)="handleSettings(item , i)"
              [ngClass]="{'rounded-t-lg rounded-b-none' : (item.status === 'CANCELLED' || 'inaktiv')}"
              class="hover:bg-[#63939c] rounded-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.details' | translate }}
            </button>
            <button
              *ngIf="checkCleaningStatus(item.status) && !checkForPastDate(item.startDate) && item.cleaningType === checkCleaningType(item.cleaningType)"
              (click)="handleReactivationCleaning(item, i)"
              class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.reactivation' | translate }}
            </button>
          </div>
          }
        </div>
        } @else {
        <div class="relative flex justify-center items-center ">
          <div (click)="handleCleaningEdit(item.id, item.projektnummer)"
            class="border-[1px] rounded-lg border-[#61BFD1] absolute flex justify-center items-center right-12 md:right-4 w-[33px] h-[30px] md:top-4 cursor-pointer">
            <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
          </div>
          @if(isCleaningOpen(item.id, item.projektnummer)){
          <div class="text-white absolute top-10 -left-24 z-10 flex flex-col rounded-lg bg-[#61BFD1]">
            <button (click)="handleSettings(item , i)"
              class="hover:bg-[#63939c] rounded-t-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.details' | translate }}
            </button>
  
            <button [ngClass]="{'rounded-b-none' : item.cleaningType === checkCleaningType(item.cleaningType)}"
              (click)="handleCancelCleanings(item, i)"
              class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.cancel' | translate }}
            </button>
            <button *ngIf="item.cleaningType === checkCleaningType(item.cleaningType)"
              (click)="handleAdditionalServices(item, i)"
              class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.additional' | translate }}
            </button>
          </div>
          }
        </div>
        }
      </div>
    }
    } @else {
    @if(loadingBookings){
    <div class="flex w-full h-[240px] bg-slate-300 animate-pulse rounded-lg"></div>
    } @else {
    <div class="p-4 w-full">
      <p class="font-bold text-[18px]">{{ 'PROFILE.Keine bevorstehenden Reinigungen' | translate }}</p>
    </div>

    }
    } } @case('Past'){ @if(getBookings('past').length > 0){ @for(item of
    getBookings('past'); track $index; let i = $index;){
      <div class="flex justify-between flex-row flex-wrap rounded-lg px-4 py-6 shadow text-[#a3a3a3] relative">
        @if(item.appointmentDate === null || undefined) {
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
          {{ 'PROFILE.date' | translate }}
          <p>{{ 'PROFILE.requested' | translate }}</p>
        </span>
        } @else {
        @if(item.cleaningDays) {
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
          {{ 'DATE.' + (item.startDate | date : "EEEE") | translate }}
          <p>{{item.startDate | date : "dd."}} {{  'DATE.' + (item.startDate | date : "MMMM") | translate }} {{item.startDate | date : "YYYY"}}</p>
        </span>
        } @else {
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%] ">
          {{ item.appointmentDate | date:'EEEE'}}
          <p>{{item.appointmentDate | date : "dd."}} {{  'DATE.' + (item.appointmentDate | date : "MMMM") | translate }} {{item.appointmentDate | date : "YYYY"}}</p>
        </span>
        }
        }
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          {{ item.address.street }} {{ item.address.streetNr }}
          <p>{{ item.address.zipCode }} {{ item.address.city }}</p>
        </span>
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          <span class="flex items-center gap-1">
            <img src="../../assets/icons/profile/home.svg" alt="home" />
            {{ 'SERVICE_TYPE.' + handleType(item.type) | translate}}
          </span>
          <span class="flex items-center gap-1"><img src="../../assets/icons/profile/recurrence.svg" alt="recurrence" />
            {{'RECURRENCE_TYPE.' + handleRecurrence(item.recurrence) | translate}}
          </span>
        </span>
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          @if(item.cleaningDays) {
            @if(item.projektnummer) {
              <span class="flex items-center gap-1">
                {{ 'PROFILE.duration' | translate }}
              </span>
              <span class="flex items-center gap-1">
                {{ transformDataAndCalculateDuration(item.additionalServices, item.startDate, item.endDate) + " " + ('PROFILE.hours' | translate)}}
              </span>
            } @else {
              <span class="flex items-center gap-1">
                {{ 'PROFILE.duration' | translate }}
              </span>
              <span class="flex items-center gap-1">
                {{ item.cleaningDays.length > 0 ? item.cleaningDays[0].duration + " " + ('PROFILE.hours' | translate) :
                formatCleaningDays(item.startDate, item.endDate) + " " + ('PROFILE.hours' | translate)}}
              </span>
            }
        }
        </span>
        <span class="flex flex-col md:py-2 md:w-[20%] w-[50%]">
          @if(item.cleaningDays) {
          <span class="my-auto">
            <span [ngClass]="{
                    'text-[#ffcc30] border-[#ffcc30]' : item.status === 'PENDING' || item.status === 'pending',
                    'border-[#ff3b30] text-[#ff3b30]' : item.status === 'CANCELLED' || item.status === 'inaktiv',
                    'border-[#34C759] text-[#34C759]' : item.status === 'ACTIVE' || item.status === 'aktiv',
                  }" class="border-[1px] border-[#61BFD1] p-1 rounded-lg">{{ 'STATUS_TYPE.' + handleCleaningStatus(item.status) | translate }}</span>
          </span>
          } @else {
          <span class="my-auto">
            @if(item.isSynchronizedCreate) {
            <span class="border-[1px] border-[#34C759] text-[#34C759] p-1 rounded-lg">{{'STATUS_TYPE.' + 'Aktiv' | translate}}</span>
            } @else {
            <span class="text-[#ffcc30] border-[#ffcc30] border-[1px] p-1 rounded-lg">{{'STATUS_TYPE.' + 'Ausstehend' | translate}}</span>
            }
          </span>
          }
        </span>
        @if(item.status === 'inaktiv' || item.status === "CANCELLED" || checkForPastDate(item.startDate) ||
        item.cleaningType === "Express" || item.cleaningType === "EXPRESS" || item.recurrence === "ONCE") {
        <div class="relative flex justify-center items-center ">
          <div (click)="handleCleaningEdit(item.id, item.projektnummer)"
            class="border-[1px] rounded-lg border-[#61BFD1] absolute flex justify-center items-center right-12 md:right-4 w-[33px] h-[30px] md:top-4 cursor-pointer">
            <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
          </div>
          @if(isCleaningOpen(item.id, item.projektnummer)){
          <div class="text-white absolute top-10 -left-24 z-10 flex flex-col rounded-lg bg-[#61BFD1]">
            <button (click)="handleSettings(item , i)"
              [ngClass]="{'rounded-t-lg rounded-b-none' : (item.status === 'CANCELLED' || 'inaktiv')}"
              class="hover:bg-[#63939c] rounded-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.details' | translate }}
            </button>
            <button
              *ngIf="checkCleaningStatus(item.status) && !checkForPastDate(item.startDate) && item.cleaningType === checkCleaningType(item.cleaningType)"
              (click)="handleReactivationCleaning(item, i)"
              class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.reactivation' | translate }}
            </button>
          </div>
          }
        </div>
        } @else {
        <div class="relative flex justify-center items-center ">
          <div (click)="handleCleaningEdit(item.id, item.projektnummer)"
            class="border-[1px] rounded-lg border-[#61BFD1] absolute flex justify-center items-center right-12 md:right-4 w-[33px] h-[30px] md:top-4 cursor-pointer">
            <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
          </div>
          @if(isCleaningOpen(item.id, item.projektnummer)){
          <div class="text-white absolute top-10 -left-24 z-10 flex flex-col rounded-lg bg-[#61BFD1]">
            <button (click)="handleSettings(item , i)"
              class="hover:bg-[#63939c] rounded-t-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.details' | translate }}
            </button>
  
            <button [ngClass]="{'rounded-b-none' : item.cleaningType === checkCleaningType(item.cleaningType)}"
              (click)="handleCancelCleanings(item, i)"
              class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.cancel' | translate }}
            </button>
            <button *ngIf="item.cleaningType === checkCleaningType(item.cleaningType)"
              (click)="handleAdditionalServices(item, i)"
              class="hover:bg-[#63939c] rounded-b-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-4">
              {{ 'PROFILE.additional' | translate }}
            </button>
          </div>
          }
        </div>
        }
      </div>
    } } @else {
    @if(loadingBookings){
    <div class="flex w-full h-[240px] bg-slate-300 animate-pulse rounded-lg"></div>
    } @else {
    <div class="p-4 w-full">
      <p class="font-bold text-[18px]"> {{ 'PROFILE.Keine früheren Reinigungen' | translate }}</p>
    </div>
    }
    } } }

  </div>
</ng-template>

<ng-template #Profile>
  <div class="flex flex-col gap-6">
    <div class="flex items-center">
      <span class="bg-violet-100 text-violet-400 rounded-md text-[20px] font-bold px-5 uppercase py-2">{{'PROFILE.profile' | translate }}</span>
    </div>
    <div class="flex flex-col gap-2 w-full h-full">
      <div class="flex flex-col md:flex-row justify-between">
        <p class="text-[28px] font-bold">{{'PROFILE.personaldata' | translate }}</p>
        @if(!showSaveBtn) {
        <button (click)="personalDataEdit()"
          class="mr-5 border-[#00829a] px-8 py-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">
          {{'PROFILE.edit' | translate }}
        </button>
        } @else {
        <button (click)="personalDataSave()"
          class="mr-5 border-[#00829a] px-8 py-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">
          {{'PROFILE.save' | translate }}
        </button>
        }
      </div>

      <form [formGroup]="userForm" class="flex flex-wrap gap-6 px-1 text-[#346973]">
        <label for="firtsName" class="w-[98%] md:w-[48%]">{{'AUTH.firstname' | translate }}
          <input formControlName="firstName" type="text" placeholder="{{'AUTH.firstname' | translate }}" class="w-full block"
            [ngClass]="{ 'border border-[#346973]': !disabledUserFields }" />
        </label>
        <label for="lastName" class="w-[98%] md:w-[48%]">
          {{'AUTH.lastname' | translate }}
          <input formControlName="lastName" type="text" placeholder="{{'AUTH.lastname' | translate }}" class="w-full block"
            [ngClass]="{ 'border border-[#346973]': !disabledUserFields }" />
        </label>
        <label *ngIf="user.clientType === 'COMPANY'" for="companyName" class="w-[98%] md:w-[48%]">{{'AUTH.companyname' | translate }}
          <input formControlName="companyName" type="text" placeholder="{{'AUTH.companyname' | translate }}" class="w-full block"
            [ngClass]="{ 'border border-[#346973]': !disabledUserFields }" />
        </label>
        <label for="birthDate" class="w-[98%] md:w-[48%]">{{'AUTH.birthdate' | translate }}
          <input formControlName="birthDate" type="date" placeholder="{{'AUTH.birthdate' | translate }}" class="w-full block" />
        </label>
        <label for="email" class="w-[98%] md:w-[48%] relative">E-Mail
          <img (mouseenter)="handleDisplayInfo()" (mouseleave)="handleDisplayInfo()"
            class="w-4 inline mb-1 cursor-pointer" src="../../assets/icons/profile/information.svg" alt="info">
          <div *ngIf="displayInformation"
            [ngClass]="{'transition ease-in-out delay-150 duration-300': displayInformation}"
            class="absolute z-50 -top-24 w-64 text-sm rounded-lg shadow-xl text-gray-900 bg-[#E9F3F4]">
            <div class="px-3 py-2 border-b rounded-t-lg bg-[#E9F3F4] text-gray-900">
              <h3 class="font-semibold">E-Mail {{'PROFILE.info' | translate }}</h3>
            </div>
            <div class="px-3 py-2">
              <p>{{'PROFILE.emailinfo' | translate }}</p>
            </div>
          </div>
          <input formControlName="email" type="text" placeholder="E-mail" class="w-full block" />
        </label>
        <label for="phoneNumber" class="w-[98%] md:w-[48%]">{{'AUTH.phone' | translate }}
          <input formControlName="phoneNumber" type="text"
            [ngClass]="{ 'border border-[#346973]': !disabledUserFields }" placeholder="{{'AUTH.phone' | translate }}"
            class="w-full block outline outline-white" />
        </label>
        <label for="cleaningReminder" class="w-full">{{'PROFILE.reminder' | translate }}
          <select name="cleaningReminder" formControlName="cleaningReminder"
            placeholder="Reinigungserinnerung auswählen" class="w-[98%] block outline outline-white"
            [ngClass]="{ 'border border-[#346973]': !disabledUserFields }">
            <option value="0">{{'PROFILE.never' | translate }}</option>
            <option value="1">{{'PROFILE.once' | translate }}</option>
            <option value="4">{{'PROFILE.four' | translate }}</option>
          </select>
        </label>
      </form>
    </div>

    <div class="flex flex-col gap-2 w-full h-full">
      @if(petsModal){
      <div
        class='absolute md:w-[60%] w-[80%] h-[450px] overflow-y-auto top-10 cleaningmodal flex flex-col bg-[#E9F3F4] shadow-lg p-6 z-10'>
        <div class="w-full bg-transparent flex justify-end gap-3">
          <span class="flex p-2 rounded-full  cursor-pointer" (click)="cancelPetsModal()"><img
              src="../../assets/icons/exit.svg" alt=""></span>
        </div>
        @if(selectedPets) {
        <div class="flex justify-between mt-4 flex-col md:flex-row">
          <div class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg">
            <span class="flex items-center gap-2">
              <img src="../assets/icons/windows-turqoise.svg" alt="icon" />
              <p class="text-[16px]">{{ "AUTH.dog" | translate }}</p>
            </span>
            <textarea [(ngModel)]="selectedPets.dogText" [value]="selectedPets.dogText" name="" id="" cols="25" rows="2"
              placeholder="{{ 'AUTH.remark' | translate }}" class="bg-gray-200 rounded-3xl p-4"></textarea>
            <div class="flex items-center gap-8 text-center justify-between">
              <button (click)="setPets('decrease', 'dog', false)"
                class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                <img src="../assets/icons/minus.svg" alt="minus" />
              </button>
              <div>
                <p class="text-[24px] font-bold">
                  {{ selectedPets.dog }}
                </p>
              </div>
              <button (click)="setPets('increase', 'dog', false)"
                class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                <img src="../assets/icons/plus.svg" alt="plus" />
              </button>
            </div>
          </div>
          <div class="flex w-full md:w-[32%] flex-col p-6 gap-8 bg-[#61BFD1] rounded-tl-lg rounded-br-lg">
            <span class="flex items-center gap-2">
              <img src="../assets/icons/window-white.svg" alt="icon" />
              <p class="text-[16px] text-white">
                {{ 'AUTH.cat' | translate }}
              </p>
            </span>
            <textarea [(ngModel)]="selectedPets.catText" [value]="selectedPets.catText" name="" id="" cols="25" rows="2"
              placeholder="{{ 'AUTH.remark' | translate }}" class="bg-gray-200 rounded-3xl p-4"></textarea>
            <div class="flex items-center gap-8 text-center justify-between">
              <button (click)="setPets('decrease', 'cat', false)"
                class="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
                <img src="../assets/icons/minus-trq.svg" alt="minus" />
              </button>
              <div>
                <p class="text-[24px] text-white font-bold">
                  {{ selectedPets.cat }}
                </p>
              </div>
              <button (click)="setPets('increase', 'cat', false)"
                class="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
                <img src="../assets/icons/plus-trq.svg" alt="plus" />
              </button>
            </div>
          </div>
          <div class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg">
            <span class="flex items-center gap-2">
              <img src="../assets/icons/windows-turqoise.svg" alt="icon" />
              <p class="text-[16px]">{{ 'AUTH.other' | translate }}</p>
            </span>
            <textarea [(ngModel)]="selectedPets.otherAnimalText" [value]="selectedPets.otherAnimalText" name="" id=""
              cols="25" rows="2" placeholder="{{ 'AUTH.remark' | translate }}" class="bg-gray-200 rounded-3xl p-4"></textarea>
            <div class="flex items-center gap-8 text-center justify-between">
              <button (click)="setPets('decrease', 'other', false)"
                class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                <img src="../assets/icons/minus.svg" alt="minus" />
              </button>
              <div>
                <p class="text-[24px] text-[#61BFD1] font-bold">
                  {{ selectedPets.otherAnimal }}
                </p>
              </div>
              <button (click)="setPets('increase', 'other', false)"
                class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                <img src="../assets/icons/plus.svg" alt="plus" />
              </button>
            </div>
          </div>
        </div>
        }

        <div class="bg-transparent flex flex-col md:flex-row gap-5 w-full justify-end p-4">
          <button class="btn-primary-outlined" (click)="cancelPetsModal()">{{ 'AUTH.cancel' | translate }}</button>
          <button class="btn-primary" (click)="confirmPets(selectedPets.id)">{{ 'AUTH.confirm' | translate }}</button>
        </div>
      </div>
      }
      <form [formGroup]="addressForm">
        <div formArrayName="addresses">
          <div *ngFor="let addressGroup of addressFormGroups; let i = index">
            <div [formGroup]="addressGroup" class="flex flex-wrap gap-6 px-1 text-[#346973]">
              <div class="flex flex-col md:flex-row justify-between w-full mt-5">
                <p class="text-[28px] font-bold">{{'AUTH.address' | translate }} {{ i + 1 }}</p>
                <button *ngIf="addressGroup.disabled" (click)="addressFieldsEdit(i)"
                  class="mr-5 border-[#00829a] py-2 px-8 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">
                  {{'PROFILE.edit' | translate }}
                </button>

                <div class="flex md:justify-end w-full md:w-[50%]" *ngIf="addressGroup.enabled">
                  <label *ngIf="addresses.length > 1" for="isDefaultAddress"
                    class="text-gray-700 text-sm font-bold flex gap-3 mr-3 items-center"><input
                      formControlName="isDefaultAddress" type="checkbox" />
                    @if(addressGroup.get('isDefaultAddress')?.value ===
                    true){ Standardadresse } @else { Standardadresse festlegen }
                  </label>
                  <button (click)="deleteAddress(i)"
                    class="border-[1px] cursor-pointer w-[200px] justify-around font-bold rounded-[6px] items-center border-[#ff3b30] hover:bg-[#ff3b303a] text-[#ff3b30] hover:duration-100 mr-3 flex gap-1">
                    {{'PROFILE.deleteaddress' | translate }}
                    <img class="" src="../../assets/icons/profile/deleteAddress.svg" alt="delete" />
                  </button>
                  <button (click)="handleSavedAddresses(i)"
                    class="mr-5 border-[#00829a] px-8 py-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">
                    {{'PROFILE.save' | translate }}
                  </button>
                </div>
              </div>
              <label (mouseenter)="handleAddressDisplayInfo()" (mouseleave)="handleAddressDisplayInfo()" for="street" class="w-[98%] md:w-[86%] relative">{{'AUTH.street' | translate }}
                <div
                *ngIf="displayAddressInformation && addressGroup.enabled"
                [ngClass]="{'transition ease-in-out delay-150 duration-300': displayAddressInformation}"
                class="absolute z-50 -top-24 w-64 text-sm rounded-lg shadow-xl text-gray-900 bg-[#E9F3F4]">
                <div class="px-3 py-2 border-b rounded-t-lg bg-[#E9F3F4] text-gray-900">
                  <h3 class="font-semibold">{{'PROFILE.address' | translate }} {{'PROFILE.info' | translate }}</h3>
                </div>
                <div class="px-3 py-2">
                  <p>{{'PROFILE.addressinfo' | translate }}</p>
                </div>
              </div>
                <input type="text" formControlName="street" placeholder="{{'AUTH.street' | translate }}" class="w-full block" />
              </label>
              <label (mouseenter)="handleAddressDisplayInfo()" (mouseleave)="handleAddressDisplayInfo()" for="streetNr" class="w-[98%] md:w-[10%]">Nr.
                <input type="text" formControlName="streetNr" placeholder="Nr." class="w-full block"  />
              </label>
              <label (mouseenter)="handleAddressDisplayInfo()" (mouseleave)="handleAddressDisplayInfo()" for="zipCode" class="w-[98%] md:w-[48%]">{{'AUTH.zip' | translate }}
                <input type="text" formControlName="zipCode" placeholder="{{'AUTH.zip' | translate }}" class="w-full block"  />
              </label>
              <label (mouseenter)="handleAddressDisplayInfo()" (mouseleave)="handleAddressDisplayInfo()" for="city" class="w-[98%] md:w-[48%]">{{'AUTH.city' | translate }}
                <input type="text" formControlName="city" placeholder="{{'AUTH.city' | translate }}" class="w-full block" />
              </label>
              <label for="additionalInformation" class="w-[98%]">
                {{'AUTH.animals' | translate }}
                <span class="flex items-center gap-6 w-[97%]">
                  <button (click)="toggleModal(i)" [ngClass]="{
                    'border border-[#346973]': addressFormGroups[i].enabled
                  }" class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] border-transparent">{{'AUTH.animals' | translate }}</button>
                  @if(this.addressFormGroups[i].get('dog')?.value){
                  <span class="relative">
                    <span
                      class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                      (click)="removeDogs(false, i)">x</span>
                    <button [ngClass]="{
                        'border border-[#346973]': addressFormGroups[i].enabled
                      }"
                      class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{'AUTH.dog' | translate }}</button>
                  </span>
                  }
                  @if(this.addressFormGroups[i].get('cat')?.value){
                  <span class="relative">
                    <span
                      class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                      (click)="removeCats(false, i)">x</span>
                    <button [ngClass]="{
                        'border border-[#346973]': addressFormGroups[i].enabled
                      }"
                      class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{'AUTH.cat' | translate }}</button>
                  </span>
                  }
                  @if(this.addressFormGroups[i].get('otherAnimal')?.value){
                  <span class="relative">
                    <span
                      class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                      (click)="removeOthers(false, i)">x</span>
                    <button [ngClass]="{
                        'border border-[#346973]': addressFormGroups[i].enabled
                      }"
                      class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{'AUTH.other' | translate }}</button>
                  </span>
                  }
                </span>
              </label>
              <label for="additionalInformation" class="w-[98%]">{{'AUTH.additionalinfo' | translate }}
                <textarea formControlName="additionalInformation" name="" id="" cols="35" rows="5"
                  placeholder="{{'AUTH.remark' | translate }}" class="bg-[#E9F3F4] rounded-3xl p-4 w-full" [ngClass]="{
                    'border border-[#346973]': addressGroup.enabled
                  }"></textarea>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    @if(displayNewAddres){
    <div class="flex flex-col gap-2 w-full h-full">
      <p class="text-[28px] font-bold">{{'PROFILE.newaddress' | translate }}</p>
      <form [formGroup]="newAddresForm">
        <div class="flex flex-wrap gap-6 px-1 text-[#346973]">
          <label for="street" class="w-[98%] md:w-[86%]">{{'AUTH.street' | translate }}
            <input type="text" formControlName="street" placeholder="{{'AUTH.street' | translate }}"
              class="w-full block border border-[#346973]" />
          </label>
          <label for="streetNr" class="w-[98%] md:w-[10%]">Nr.
            <input type="text" formControlName="streetNr" placeholder="Nr."
              class="w-full block border border-[#346973]" />
          </label>
          <label for="zipCode" class="w-[98%] md:w-[48%]">{{'AUTH.zip' | translate }}
            <input type="text" formControlName="zipCode" placeholder="{{'AUTH.zip' | translate }}"
              class="w-full block border border-[#346973]" />
          </label>
          <label for="city" class="w-[98%] md:w-[48%]">{{'AUTH.city' | translate }}
            <input type="text" formControlName="city" placeholder="{{'AUTH.city' | translate }}" class="w-full block border border-[#346973]" />
          </label>
          @if(newPetsModal){
          <div
            class='absolute md:w-[60%] w-[80%] top-10 h-[450px] overflow-y-auto cleaningmodal flex flex-col bg-[#E9F3F4] shadow-lg p-6 z-10'>
            <div class="w-full bg-transparent flex justify-end gap-3">
              <span class="flex p-2 rounded-full  cursor-pointer" (click)="toggleNewPetsModal()"><img
                  src="../../assets/icons/exit.svg" alt=""></span>
            </div>
            <div class="flex justify-between mt-4 flex-col md:flex-row">
              <div class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg">
                <span class="flex items-center gap-2">
                  <img src="../assets/icons/windows-turqoise.svg" alt="icon" />
                  <p class="text-[16px]">{{'AUTH.dog' | translate }}</p>
                </span>
                <textarea formControlName="dogText" name="" id="" cols="25" rows="2" placeholder="{{'AUTH.remark' | translate }}"
                  class="bg-gray-200 rounded-3xl p-4"></textarea>
                <div class="flex items-center gap-8 text-center justify-between">
                  <button (click)="setPets('decrease', 'dog', true)"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                    <img src="../assets/icons/minus.svg" alt="minus" />
                  </button>
                  <div>
                    <p class="text-[24px] font-bold">
                      {{ newAddresForm.get('dog')?.value }}
                    </p>
                  </div>
                  <button (click)="setPets('increase', 'dog', true)"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                    <img src="../assets/icons/plus.svg" alt="plus" />
                  </button>
                </div>
              </div>
              <div class="flex w-full md:w-[32%] flex-col p-6 gap-8 bg-[#61BFD1] rounded-tl-lg rounded-br-lg">
                <span class="flex items-center gap-2">
                  <img src="../assets/icons/window-white.svg" alt="icon" />
                  <p class="text-[16px] text-white">
                    {{'AUTH.cat' | translate }}
                  </p>
                </span>
                <textarea formControlName="catText" name="" id="" cols="25" rows="2" placeholder="{{'AUTH.remark' | translate }}"
                  class="bg-gray-200 rounded-3xl p-4"></textarea>
                <div class="flex items-center gap-8 text-center justify-between">
                  <button (click)="setPets('decrease', 'cat' , true)"
                    class="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
                    <img src="../assets/icons/minus-trq.svg" alt="minus" />
                  </button>
                  <div>
                    <p class="text-[24px] text-white font-bold">{{ newAddresForm.get('cat')?.value }}</p>
                  </div>
                  <button (click)="setPets('increase', 'cat', true)"
                    class="w-12 h-12 bg-white rounded-lg flex items-center justify-center">
                    <img src="../assets/icons/plus-trq.svg" alt="plus" />
                  </button>
                </div>
              </div>
              <div class="flex w-full md:w-[32%] my-5 md:my-0 flex-col p-6 gap-8 rounded-tl-lg rounded-br-lg">
                <span class="flex items-center gap-2">
                  <img src="../assets/icons/windows-turqoise.svg" alt="icon" />
                  <p class="text-[16px]">{{'AUTH.other' | translate }}</p>
                </span>
                <textarea formControlName="otherAnimalText" name="" id="" cols="25" rows="2" placeholder="{{'AUTH.remark' | translate }}"
                  class="bg-gray-200 rounded-3xl p-4"></textarea>
                <div class="flex items-center gap-8 text-center justify-between">
                  <button (click)="setPets('decrease', 'other', true)"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                    <img src="../assets/icons/minus.svg" alt="minus" />
                  </button>
                  <div>
                    <p class="text-[24px] text-[#61BFD1] font-bold">
                      {{ newAddresForm.get('otherAnimal')?.value }}
                    </p>
                  </div>
                  <button (click)="setPets('increase', 'other', true)"
                    class="w-12 h-12 bg-[#61BFD1] rounded-lg px-2 flex items-center justify-center">
                    <img src="../assets/icons/plus.svg" alt="plus" />
                  </button>
                </div>
              </div>
            </div>
            <div class="bg-transparent flex flex-col md:flex-row gap-5 w-full justify-end p-4">
              <button class="btn-primary-outlined" (click)="toggleNewPetsModal()">{{'AUTH.cancel' | translate }}</button>
              <button class="btn-primary" (click)="toggleNewPetsModal()">{{'AUTH.confirm' | translate }}</button>
            </div>
          </div>
          }
          <label for="additionalInformation" class="w-[98%]">
            {{'AUTH.animals' | translate }}
            <span class="flex items-center gap-6 w-[97%]">
              <button (click)="toggleNewPetsModal()"
                class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] border-transparent">{{'AUTH.animals' | translate }}</button>
              @if(this.newAddresForm.get('dog')?.value){
              <span class="relative">
                <span
                  class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                  (click)="removeDogs(true)">x</span>
                <button
                  class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{'AUTH.dog' | translate }}</button>
              </span>
              }
              @if(this.newAddresForm.get('cat')?.value){
              <span class="relative">
                <span
                  class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                  (click)="removeCats(true)">x</span>
                <button disabled
                  class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{'AUTH.cat' | translate }}</button>
              </span>
              }
              @if(this.newAddresForm.get('otherAnimal')?.value){
              <span class="relative">
                <span
                  class="absolute cursor-pointer rounded-full bg-red-200 text-red-500 flex items-center justify-center w-[24px] h-[24px] -top-2 -right-2"
                  (click)="removeOthers(true)">x</span>
                <button disabled
                  class="bg-[#E9F3F4] rounded-lg p-4 outline-none border-[1px] text-[#a5a5a5] border-transparent">{{'AUTH.other' | translate }}</button>
              </span>
              }
            </span>
          </label>
          <label for="additionalInformation" class="w-[98%]">{{'AUTH.additionalinfo' | translate }}
            <textarea formControlName="additionalInformation" name="" id="" cols="35" rows="5" placeholder="{{'AUTH.remark' | translate }}"
              class="bg-[#E9F3F4] rounded-3xl p-4 w-full block border border-[#346973]">
            </textarea>
          </label>
        </div>
      </form>
    </div>
    }
    <div class="flex flex-col md:flex-row items-center mb-1">
      <button (click)="addNewAddres()"
        class="border-[#00829a] px-8 py-2 box-border font-bold rounded-md border-[1px] hover:opacity-55 text-[#00829a]">
        {{'PROFILE.addaddress' | translate }}
      </button>
      <button (click)="cancelNewAddres()" *ngIf="displayNewAddres"
        class="px-8 py-2 box-border mt-5 md:mt-0 ml-3 font-bold rounded-md border-[1px] border-[#ff3b30] hover:bg-[#ff3b303a] text-[#ff3b30]">
        {{'AUTH.cancel' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #Settings>
  <div class="flex flex-col gap-6">
    <div class="flex items-center">
      <span class="bg-violet-100 text-violet-400 rounded-md text-[20px] font-bold px-5 py-2 uppercase">{{'PROFILE.settings' | translate }}</span>
    </div>
    <div>
      <p class="text-[28px] font-bold">
        {{'PROFILE.changepass' | translate }}</p>
      <form [formGroup]="changePasswordForm" class="flex md:flex-row flex-col gap-6 relative">
        <input formControlName="password" type="password"
          class="bg-[#e8eaf3] w-full md:w-[40%] px-2 rounded-lg text-black" placeholder="{{'AUTH.password' | translate }}" />
        <input formControlName="confirmPassword" type="password"
          class="bg-[#e8eaf3] w-full md:w-[40%] px-2  ounded-lg text-black" placeholder="{{'AUTH.repeatpass' | translate }}" />
        @if(changePasswordForm.get('password')?.value !==
        changePasswordForm.get('confirmPassword')?.value){
        <span class="text-red-500 absolute left-0 -bottom-6">{{'AUTH.passmatch' | translate }}</span>
        }
        <button (click)="changePassword()"
          class="border-[#00829a] box-border font-bold py-2 px-4 rounded-md border-[1px] hover:opacity-55 text-[#00829a]">
          {{'PROFILE.save' | translate }}
        </button>
      </form>

    </div>
    <div>
      <p class="md:text-[28px] text-[24px] font-bold">{{'PROFILE.replacementcleaner' | translate }}</p>
      <p class="text-[#5f5f5f] text-[20px]">
        {{'PROFILE.cleanerinfo' | translate }}
      </p>
    </div>
    <div class="text-[#5f5f5f] text-[20px]">
      <label class="text-[20px] flex gap-3 items-center">
        <input type="radio" [value]="true" [checked]="hasReplacement" (change)="onReplacementHandle(true)" />
        {{'PROFILE.stvyes' | translate }}
      </label>
      <label class="text-[20px] flex gap-3 items-center">
        <input type="radio" [value]="false" [checked]="!hasReplacement" (change)="onReplacementHandle(false)" />
        {{'PROFILE.stvno' | translate }}
      </label>
      <p></p>
      <p></p>
    </div>
    <div class="flex justify-end">
      <button (click)="deleteAccount()"
        class="border-[1px] border-[#ff3b30] p-2 justify-between font-bold rounded-[6px] px-8 py-2 hover:bg-[#ff3b303a] text-[#ff3b30] hover:duration-100 flex items-center gap-1">
        {{'PROFILE.deleteprofile' | translate }}
        <img src="../../assets/icons/profile/del.svg" alt="" />
      </button>
    </div>
  </div>
</ng-template>

<ng-template #Orders>
  <div class="min-h-[380px]">
    <div class="sm:flex sm:flex-col sm:gap-6">
      <div class="flex items-center">
        <span class="bg-violet-100 uppercase text-violet-400 rounded-md text-[20px] font-bold px-5 py-2">
          {{'PROFILE.invoices' | translate}}
        </span>
      </div>
      <p class="text-[28px] font-bold">{{'PROFILE.paymenthistory' | translate}}</p>
      @if(transactions.length > 0) {
      <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-7">
        <div class="font-bold md:ml-2">{{'PROFILE.dateandtime' | translate}}</div>
        <div class="font-bold sm:col-start-3">{{'PROFILE.paymentmethod' | translate}}</div>
        <div class="font-bold sm:col-start-5">Status</div>
        <div class="font-bold sm:col-start-7">{{'PROFILE.amount' | translate}}</div>
        <ng-container *ngFor="let transaction of transactions; let i = index">
          <div
            class="bg-[#e9f3f4] flex items-center text-[#5f5f5f] px-2 mt-3 py-4 rounded-lg md:rounded-none md:rounded-l-lg md:col-span-2">
            {{ formatTimestamp(transaction.createdAt) }} {{'PROFILE.time' | translate}}
          </div>
          <div
            class="bg-[#e9f3f4] flex items-center text-[#5f5f5f] px-2 md:px-0 mt-3 rounded-lg md:rounded-none md:col-start-3 md:col-span-2 py-4">
            {{ transaction.transactionType === 'DIRECT_PAYMENT' ? ('PROFILE.direktpayment' | translate) : ('PROFILE.monthlypayment' | translate) }}
          </div>
          <div
            class="bg-[#e9f3f4] flex items-center text-[#5f5f5f] px-2 md:px-0 mt-3 md:col-span-2 md:col-start-5 py-4 rounded-lg md:rounded-none md:rounded-r-lg capitalize md:mr-3">
            {{ 'TRANSACTION_STATUS.' + handleTransactionStatus(transaction.status) | translate }}
          </div>
          <div
            class="bg-[#e9f3f4] flex md:justify-center mt-3 md:items-center text-[#5f5f5f] px-2 md:px-0 py-4 sm:col-start-7 rounded-lg">
            {{ transaction.currency }} {{ formatAmount(transaction.amount) }}
          </div>
        </ng-container>
      </div>
      } @else {
      <div class="w-full">{{'PROFILE.notransactions' | translate}}</div>
      }
    </div>
  </div>
</ng-template>

<ng-template #CCard>
  <div class="min-h-[380px]">
    <div class="sm:flex sm:flex-col sm:gap-6">
      <div class="flex justify-between md:flex-row flex-col items-center">
        <span
          class="bg-violet-100 text-violet-400 rounded-md text-[20px] font-bold px-5 py-2 uppercase">{{ "PROFILE.payment" | translate }}</span>
        <div *ngIf="this.user.paymentType !== null" class="flex gap-2 items-center">
          <label class="md:text-[20px] text-[16px] flex gap-3 items-center py-2">
            <input [disabled]="requestedPaymentChange" type="radio" [checked]="paymentType === 'CARD'"
              class="regular-checkbox" (change)="handlePaymentMethod('CARD')" />
            <span class="flex flex-col md:flex-row gap-0 md:gap-2">
              <p>{{ "PROFILE.creditcard" | translate }}</p>
            </span>
          </label>
          <label class="md:text-[20px] text-[16px] flex gap-3 items-center py-2">
            <input [disabled]="requestedPaymentChange" [checked]="paymentType === 'INVOICE'" type="radio"
              class="regular-checkbox" (change)="handlePaymentMethod('INVOICE')" />
            <span class="flex flex-col md:flex-row gap-0 md:gap-2">
              <p>{{ "PROFILE.invoice" | translate }}</p>
            </span>
          </label>
        </div>
      </div>
      @if(user.paymentMethodChanges[0]) {
      <div *ngIf="user.paymentMethodChanges[0].status === 'TODO'"
        class="text-sm rounded-lg shadow-xl text-gray-900 border border-[#61BFD1]">
        <div class="px-3 py-2 border-b border-[#61BFD1] rounded-t-lg flex gap-2 text-gray-900">
          <img class="w-4 mb-1" src="../../assets/icons/profile/information.svg" alt="info">
          <h3 class="font-semibold">Information</h3>
        </div>
        <div class="px-3 py-2">
          <p>{{ "PROFILE.paymentnotf" | translate }} {{
            user.paymentMethodChanges[0].newPaymentMethod === "INVOICE" ?  ("PROFILE.invoice" | translate ) : ("PROFILE.invoice" | translate ) }}
            {{ "PROFILE.paymentnotf2" | translate }} <a class="underline"
              href="mailto: info@pebra.ch">info&#64;pebra.ch</a>
          </p>
        </div>
      </div>
      }
      <div>
      </div>
      @if(cardDetails.length > 0) {
      <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-7">
        <div class="font-bold md:ml-2"> {{ "PROFILE.cardtype" | translate }}</div>
        <div class="font-bold sm:col-start-3"> {{ "PROFILE.cardnumber" | translate }}</div>
        <div class="font-bold sm:col-start-5"> {{ "PROFILE.expirydate" | translate }}</div>
        <div class="font-bold sm:col-start-7"> {{ "PROFILE.more" | translate }}</div>

        <ng-container *ngFor="let details of cardDetails; let i = index">
          <div
            class="bg-[#e9f3f4] flex items-center text-[#5f5f5f] px-2 mt-3 py-4 capitalize rounded-lg md:rounded-none md:rounded-l-lg md:col-span-2">
            {{ details.brand }}
          </div>
          <div
            class="bg-[#e9f3f4] flex items-center text-[#5f5f5f] px-2 md:px-0 mt-3 rounded-lg md:rounded-none md:col-start-3 md:col-span-2 py-4">
            {{ details.cardNumber }}
          </div>
          <div
            class="bg-[#e9f3f4] flex items-center text-[#5f5f5f] px-2 md:px-0 mt-3 md:col-span-2 md:col-start-5 py-4 rounded-lg md:rounded-none md:rounded-r-lg capitalize md:mr-3">
            {{ details.expiry }}
          </div>
          <div
            class="bg-[#e9f3f4] flex justify-center relative mt-3 items-center text-[#5f5f5f] px-2 md:px-0 py-4 sm:col-start-7 rounded-lg">
            <div (click)="handleCardEdit(details.id)"
              class="border-[1px] rounded-lg flex justify-center items-center right-4 w-[33px] h-[30px] cursor-pointer">
              <img src="../../assets/icons/profile/3-dots.svg" alt="dots" />
            </div>
            @if(isSettingsOpen(details.id)){
            <div class="text-white absolute top-10 z-30 flex flex-col rounded-lg bg-[#61BFD1]">
              <span
                class="hover:bg-[#63939c] rounded-t-lg cursor-pointer border-b-[1px] border-b-[#ededed] py-2 px-2">@if(details.isDefault)
                { {{ "PROFILE.defaultcard" | translate }} } @else {
                <span (click)="setDefaultP(details.id)"> {{ "PROFILE.setdefault" | translate }} </span>
                }</span>
              @if(!details.isDefault || user.paymentType === 'INVOICE') {
              <span (click)="handleCreditDelete(details.id)"
                class="hover:bg-[#FFCECB] rounded-b-lg cursor-pointer py-2 px-4">{{ "PROFILE.deletecard" | translate }}</span>
              }
            </div>
            }
          </div>
        </ng-container>
      </div>
      } @else if((this.cardDetails.length === 0 && this.user.paymentType === "CARD") || ( this.user.paymentType === null)) {
        <div
        class="text-sm md:mt-0 mt-3 shadow-xl rounded-lg border text-gray-900 border-[#ffcc30]">
        <div class="px-3 py-2 border-b border-[#ffcc30] rounded-t-lg flex gap-2 ">
          <img class="w-4 mb-1" src="../../assets/icons/profile/triangle.svg" alt="info">
          <h3 class="font-semibold">{{ "PROFILE.warning" | translate }}</h3>
        </div>
        <div class="px-3 py-2">
          <p>{{ "PROFILE.nopaymentmethod" | translate }}</p>
        </div>
      </div>
      }  @else if(user.paymentMethodChanges.length > 0) {
        @if(user.paymentMethodChanges[0].newPaymentMethod === "CARD"){
          <div
          class="text-sm md:mt-0 mt-3 shadow-xl rounded-lg border text-gray-900 border-[#ffcc30]">
          <div class="px-3 py-2 border-b border-[#ffcc30] rounded-t-lg flex gap-2 ">
            <img class="w-4 mb-1" src="../../assets/icons/profile/triangle.svg" alt="info">
            <h3 class="font-semibold">{{ "PROFILE.warning" | translate }}</h3>
          </div>
          <div class="px-3 py-2">
            <p>{{ "PROFILE.nopaymentmethod" | translate }}</p>
          </div>
        </div>
        }
    }
      <div class="mt-5">
        <button (click)="addNewC(false)"
          class="border-[1px] box-border disabled:opacity-25 border-[#61BFD1] font-bold rounded-[6px] px-8 py-2 hover:bg-[#61bfd13a]">
          {{ "PROFILE.addcard" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>